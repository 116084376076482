const EvidenceTypeEnum = Object.freeze({
    None: 0,
    Text: 1,
    Api_Count: 2,
    Api_Threshold: 3,
    List: 4,
    OpenList: 5,
    LinkedObjective: 6,
    SMART_Objective: 7,
    Linked_Award: 8
  });
  
  export default EvidenceTypeEnum;