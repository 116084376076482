<template>
  <b-container>
    <b-card class="text-center" body-bg-variant="info" body-text-variant="white">
      <b-img left src="images\strslogo-sm.png" alt="Right image"></b-img><b-img right src="images\strslogo-sm.png" alt="Right image"></b-img>
      <h1>Tommy's Award</h1>
    </b-card>
    <b-card no-body>
      <b-tabs content-class="mt-3">
        <b-tab title="Categories" active>
          <b-card-text class="col-6 offset-md-3">
            <h4>Tommy's Award Categories</h4>
            <b-form-group label="Select Award">
            <b-select @change="selectYear($event)" v-model="awardId">
                    <b-select-option value="1">Bronze</b-select-option>
                    <b-select-option value="2">Silver</b-select-option>
            </b-select>
            </b-form-group>
            <div class="accordion" role="tablist">
              <b-card  v-for="category in categories" :key="category.categoryId" no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="'accordion-' + category.categoryId" variant="info">{{category.categoryName}}</b-button>
              </b-card-header>
              <b-collapse :id="'accordion-' + category.categoryId" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  
                  <hr>
                  <b-list-group v-for="objective in category.objectives" :key="objective.objectiveId">
                    <b-list-group-item>{{objective.name}} <b-button size="sm" variant="outline-primary" class="float-right" @click="editObjective(objective)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button></b-list-group-item>
  
                  </b-list-group>
                  <b-button class="float-right" size="sm" variant="primary" @click="addObjective(category)">
                    Add
                  </b-button>
                </b-card-body>
              </b-collapse>
            </b-card>
            </div>
            
          </b-card-text>  
        </b-tab>
        <b-tab title="Staff"><p>I'm the second tab</p></b-tab>
        
      </b-tabs>
    </b-card>


    <b-modal v-model="showEditObjective" title="Edit Objective">
    
      <b-form-group label="Name" label-for="name">
        <b-input v-model="currentObjective.name"></b-input>
      </b-form-group>
      <b-form-group label="Evidence Type" label-for="type">
        <b-select v-model="currentObjective.evidenceType">
          <b-select-option value="1">Text</b-select-option>
          <b-select-option value="2">API Count</b-select-option>
          <b-select-option value="3">API Threshold</b-select-option>
          <b-select-option value="4">List</b-select-option>
          <b-select-option value="5">Linked Objective</b-select-option>
          <b-select-option value="6">SMART Objective</b-select-option>
          <b-select-option value="7">Linked Award</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group description="Enter the default member of staff to approve the evidence" label="Default Approver" label-for="defaultApprover">
        <b-form-select id="approver" v-model="currentObjective.defaultApprover">
          <b-form-select-option  v-for="staffMember in staff" :key="staffMember.userName" :value="staffMember.userName">{{staffMember.name}}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Success Criteria" label-for="successCriteria">
        <b-input v-model="currentObjective.successCriteria"></b-input>
      </b-form-group>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="primary" @click="saveObjective()" > 
            OK
          </b-button>
          <b-button size="sm" variant="secondary" @click="cancel()">
            Close
          </b-button>
        </template>
    </b-modal>

    <!-- Linked Objective  -->
    <!-- ##################################################################### -->
    <b-modal id="link-objective" title="Link Objective" @ok="savelink" v-model="showLinkObjective">
      <b-form-select v-model="currentSection.optionId">
        <b-form-select-option v-for="option in currentOptions" :key="option.optionId" :value="option.optionId">{{option.optionName}}</b-form-select-option>
      </b-form-select>
      <b-form-group label="Evidence" label-for="book-review">
          <b-form-textarea id="sectionEvidence"  v-model="currentSection.evidence" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
        </b-form-group>
    </b-modal>
    <!-- ##################################################################### -->
  </b-container>
</template>

<script>
  import axios from 'axios';
  import {router} from '../routes.js'
  import {AuthLevelEnum} from "./../enums"

  export default ({
    name: 'Admin',
    props: {
      showModal: Boolean,
      person: Object
    },
    data() {
      return {
        fields: ['categoryName'],
        categories:[], //{categoryName: 'Leadership', categoryId: 1}, {categoryName: 'Service and Commitment', categoryId: 2}],
        objectives: [{objectiveName: "Take part in Founder's week", objectiveId: 1},{objectiveName: "Attendance 98%", objectiveId:2},{objectiveName: "Give a tour to a school visitor", objectiveId:3}],
        currentObjective: {objectiveName: "Take part in Founder's week", objectiveId: 1},
        showEditObjective: false,
        staff: ['Him', 'AKM', 'RTY'],
        currentCategory: null,
        awardId: null
      }
    },
    methods: {
      editObjective(objective) {
        this.currentObjective = objective;
        this.showEditObjective = true;
      },
      addObjective(category) {
        this.currentCategory = category;
        this.currentObjective = {objectiveName: "", evidenceType: 1, categoryId: category.categoryId, awardId: this.awardId};
        this.showEditObjective = true;
      },
      saveObjective() {
        let that = this;
        if (this.currentObjective.objectiveId) {
          axios.put(window.API_URL + 'objectives/' + this.currentObjective.objectiveId, this.currentObjective).then(function(response) {
            console.log(response);
            that.currentObjective = response.data;
          });
        }
        else {
          axios.post(window.API_URL + 'objectives', this.currentObjective).then(function(response) {
            console.log(response);
            that.currentObjective = response.data;
            that.currentCategory.objectives.push(that.currentObjective);
          });
        }
        this.showEditObjective = false;
      },
      selectYear(event) {
        
        // retieve objectives
        let that = this;
        this.awardId = event;
        axios.get(window.API_URL + 'objectives?awardid=' + event).then(function(response) {
          //console.log(response);
          that.categories = response.data;
        });
      }
    },
    mounted: function() {
      console.log("Admin Mounted", this.person);
      axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
      if (this.person.authLevel === AuthLevelEnum.Student) {
        router.push({name: 'home'})
      }
      else {
        let that = this;
        //axios.get(window.API_URL + 'categories').then(function(response) {
        //console.log(response);
        //that.categories = response.data;
        //});

        axios.get(window.API_URL + 'staff').then(function(response) {
          //console.log(response);
          that.staff = response.data;
        });

      }
  },
})
</script>