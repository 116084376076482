//import Vue from 'vue'
import VueRouter from 'vue-router'
import Student from './components/Student.vue'
import Staff from './components/Staff.vue'
import Admin from './components/Admin.vue'
import Home from './components/Home.vue'
import Smart from './components/Smart.vue'
import ReadingAward from './components/ReadingAward.vue'

const routes = [
    { path: '/student', component: Student, name: 'student' },    
    { path: '/staff/:award', component: Staff, name: 'staff' },
    { path: '/admin', component: Admin, name: 'admin' },
    { path: '/smartobjectives', component: Smart, name: 'smart' },
    { path: '/readingaward', component: ReadingAward, name: 'readingaward' },
    { path: '', component: Home, name: 'home' }
  ]

export const router = new VueRouter({
  routes // short for `routes: routes`
})