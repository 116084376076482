<template>
  <b-container>
    <h4>Loading...</h4>
  </b-container>
</template>

<script>
//import axios from 'axios';

export default {
  name: 'Home',
  props: {
    msg: String
  },
  //data: function() {
    // message: 'Hello Vue.js!'
  //},
  methods: {
    reverseMessage: function () {
      this.message = this.message.split('').reverse().join('')
    }
  },
  mounted: function() {
	
	
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4{
  margin: 10px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
