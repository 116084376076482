<template>
  <b-container>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/staff/tommy">Student Awards Portal</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-navbar-nav>
            <b-nav-item to="/staff/tommy">Tommy's Award</b-nav-item>
            <b-nav-item to="/staff/smart">SMART Objectives</b-nav-item>
            <b-nav-item to="/staff/reading">Reading Award</b-nav-item>
          </b-navbar-nav>
      </b-navbar>
    </div>
    <b-card class="text-center" body-bg-variant="info" body-text-variant="white">
      <b-img left src="images\strslogo-sm.png" alt="Right image"></b-img><b-img right src="images\strslogo-sm.png" alt="Right image"></b-img>
      <h1>{{ getTitle() }}</h1>
    </b-card>
    <div>
  <b-card no-body>
    <b-tabs card v-model="tabIndex"> <!-- Outer Tabs -->
      <b-tab active> <!-- First Outer Tabs -->
        <template slot="title">
          Approvals
          <b-badge pill variant="danger">{{pending(activities).length}}</b-badge>
        </template>
        <b-card-text> <!-- Outer Tab text -->
          
          <b-tabs pills  > <!-- Inner Tabs -->
            <b-tab active title="Pending"> <!-- First Inner tabs -->
              <b-table striped hover :items="pending(activities)" :fields="fields">
                <template #cell(evidence.description)="data">
                  <span class="whitespace" :id="'span' + data.item.evidence.evidenceId">{{getEvidence(data.value)}}</span>
                  <b-popover v-if="data.value && Array.from(data.value)[0] === '['" :target="'span' + data.item.evidence.evidenceId" triggers="hover" placement="top">
                                    <template #title>Evidence</template>
                                    <ul>
                                        <li v-for="evi in getEvidenceArray(data.value)" :key="evi.value">{{evi.value}} ({{ evi.optionName }}) {{ evi.evidence }}</li>
                                    </ul>
                                </b-popover> 
                </template>
                <template #cell(evidence.studentId)="data">
                  <b-button-group>
                    <b-button size="sm" @click="rejectEvidenceDialog(data.item)" variant="outline-danger" title="Reject Evidence"><b-icon-flag-fill></b-icon-flag-fill></b-button>
                    <b-button size="sm" @click="approveEvidence(data.item)" variant="outline-success" title="Approve Evidence"><b-icon-flag-fill></b-icon-flag-fill></b-button>
                  </b-button-group>
                </template>
              </b-table>
            </b-tab><!-- First Inner Tab -->

          <b-tab title="Approved"> <!-- Second Inner Tab -->

          <b-card-text>
          <b-table striped hover :items="approved(activities)" :fields="fields">
            <template #cell(evidence.description)="data">
              <span class="whitespace">{{data.value}}</span>
            </template>
            <template #cell(evidence.studentId)="data">
                  <b-button-group>
                    <b-button size="sm" @click="undoApproval(data.item)" variant="outline-danger" title="Undo Approval"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                  </b-button-group>
                </template>  
          </b-table>
       </b-card-text>
      </b-tab> <!-- Second Inner Tab -->
      <b-tab title="Rejected"> <!-- Third Inner Tab -->

          <b-card-text>
          <b-table striped hover :items="rejected(activities)" :fields="fields" select-mode="multi" selectable>
            <template #cell(evidence.description)="data">
              <span class="whitespace">{{data.value}}</span>
            </template>
            <template #cell(evidence.studentId)="data">
                  <b-button-group>
                    <b-button size="sm" @click="undoApproval(data.item)" variant="outline-danger" title="Undo Rejection"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                  </b-button-group>
                </template>  
      </b-table>
       </b-card-text>
      </b-tab> <!-- Third Inner Tab -->
      </b-tabs> <!-- Inner Tabs End-->
        </b-card-text>
      </b-tab>
      <b-tab v-if="person.authLevel == authLevel.Admin"> <!-- Second Outer Tabs -->
        <template slot="title">
          Awards
          <b-badge pill variant="danger">{{pendingAwards(awards).length}}</b-badge>
        </template>
        <b-card-text>
          <b-tabs pills  > <!-- Inner Tabs -->
            <b-tab active title="New"> <!-- First Inner tabs -->
              <b-table striped hover :items="pendingAwards(awards)" :fields="awardFields" >
                <!--<template #cell(studentId)="data">
                  <b-button-group>
                    <b-button size="sm" @click="undoAward(data.item)" variant="outline-danger" title="Undo Award"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                  </b-button-group>
                </template>  -->
              </b-table>
              <p>
                <b-button size="sm" @click="exportAwards()" title="Export to CSV file, this will mark the awards as given">Export List</b-button>&nbsp;
                <!--<b-button size="sm" >Create Certificates</b-button>&nbsp;-->
              </p>
            </b-tab>
            <b-tab title="All"> <!-- Second Inner tabs -->
              <b-form-group label="Award" label-for="awardName">
                <v-select label="award" :filterable="false" :options="awardTypes" v-model="selectedAwardType">      
                </v-select>
              </b-form-group>
              <b-table striped hover :items="awards.filter(award=>award.awardName == selectedAwardType)" :fields="awardFields">
              </b-table>
            </b-tab>
          </b-tabs>
          
        </b-card-text>
      </b-tab>
      <b-tab title="Student">
        <b-form-group label="Find Student" label-for="studentName">
          <v-select label="name" :filterable="false" :options="students" @search="onStudentSearch" @input="onStudentSelect" :value="selectedStudent">
            
          </v-select>
        </b-form-group>
        <b-card-text v-if="selectedStudent.awards[0].awardName === 'None'">No award available for this student</b-card-text>
        <div class="mt-3">
      <b-card-group deck >
        <b-card v-for="category in selectedStudent.awards[0].categories" :title="category.categoryName" :key="category.categoryId" no-body style="max-width: 25rem; min-width: 20rem; margin-bottom: 1rem;">
          <b-card-body body-class="title"><b-card-title>{{category.categoryName}}</b-card-title></b-card-body>
          <b-card-body body-text-variant="black" v-if="completed(category.objectives).length" body-class="done">
          <b-card-title>Done</b-card-title>
          <b-card-text v-for="activity in completed(category.objectives)" :key="activity.name" >
          <span v-if="activity.status==objectiveStatus.Complete">(Pending)</span> {{activity.name}} <b-button size="sm" variant="outline-primary" @click="editActivity(activity)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button>
          </b-card-text>
          </b-card-body>
          <b-card-body body-class="todo" body-text-variant1="black" v-if="incomplete(category.objectives).length">
          <b-card-title>To do</b-card-title>
          <b-card-text v-for="activity in incomplete(category.objectives)" :key="activity.name">
            {{activity.name}} <b-button size="sm" variant="outline-primary" @click="editActivity(activity)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button>
          </b-card-text>
          </b-card-body>
        </b-card>
      </b-card-group>
    </div>
        
      </b-tab>
      <b-tab title="Tutor Group" v-if="person.tutorGroup">
        <tutor-group-list :person="person" :tutorGroupName="person.tutorGroup" :awardType="awardType" v-on:click="showStudent" v-on:award="awardStudent" v-on:undoAward="undoAward"></tutor-group-list>
      </b-tab>
      <b-tab title="Tutor Groups" v-if="person.authLevel == authLevel.Admin">
        <v-select label="Tutor Group" :filterable="false" :options="tutorGroups" v-model="selectedTutorGroup">    
        </v-select>
        <tutor-group-list :person="person" :tutorGroupName="selectedTutorGroup" v-on:click="showStudent" :awardType="awardType" v-on:award="awardStudent" v-on:undoAward="undoAward"></tutor-group-list>
      </b-tab>
      <b-tab title="Admin" v-if="person.authLevel == authLevel.Admin"> 
        <b-card-text> 
          <objectives :person="person" :awardType="awardType"></objectives>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</div>
  <b-modal v-model="showReject" :title="currentActivity.name">
    <b-form-text>{{currentEvidence}}</b-form-text><hr>
    <b-form-group description="" label="Reason for Rejection" label-for="reason">
          <b-form-textarea id="reason" v-model="currentActivity.rejectionReason">
          </b-form-textarea>
        </b-form-group>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="outline-secondary" @click="rejectEvidence()" > 
            OK
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
        </template>
  </b-modal>
  <b-toast id="reject-toast" title="Evidence Rejected" variant="warning" auto-hide-delay="6000">
      You <strong>rejected</strong> {{this.currentActivity.studentName}}'s evidence for {{this.currentActivity.name}}
      <br><b-button size="sm" @click="undoApproval()">Undo</b-button>
    </b-toast>
    <b-toast id="approve-toast" title="Evidence Approved" variant="success" auto-hide-delay="6000">
      You <strong>approved</strong> {{this.currentActivity.studentName}}'s evidence for {{this.currentActivity.name}}
      <br><b-button size="sm" @click="undoApproval()">Undo</b-button>
    </b-toast>

    <b-modal v-model="showActivity" :title="currentActivity.name">
        <b-form-group v-if="currentActivity.evidenceType == this.evidenceType.Text" description="Add Evidence for completing this activity" label="Evidence" label-for="evidence">
          <b-form-textarea id="evidence"  v-model="currentActivity.evidence.description" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
        </b-form-group>
        <b-form-group v-if="isCompleted(currentActivity)" description="Enter reason for marking as not completed" label="Reason" label-for="reason">
          <b-form-textarea id="reason"  v-model="currentActivity.evidence.rejectReason"></b-form-textarea>
        </b-form-group>
        <b-form-text text-variant="info" v-if="isAPI(currentActivity)">
          <strong>Currently at {{currentActivity.evidence.description}}</strong><br>
          <span v-if="!isCompletable(currentActivity)">This objective can only be completed once all other objectives are complete.</span>
        </b-form-text>
        
        <b-form-text text-variant="success" v-if="currentActivity.status==objectiveStatus.Approved"><strong>Completed and approved</strong></b-form-text>
        <b-form-text text-variant="info" v-else-if="currentActivity.status==objectiveStatus.Complete"><strong>Completed, pending approval</strong></b-form-text>
        <b-form-text text-variant="danger" v-else><strong>Incomplete</strong></b-form-text>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="outline-secondary" @click="saveEvidence(false)" v-show="isEditable(currentActivity) && !isAPI(currentActivity)"> 
            Save
          </b-button>
          <!-- Button with custom close trigger value -->
          <b-button size="sm" variant="success" @click="saveEvidence(true)" v-show="isCompletable(currentActivity)">
            Complete
          </b-button>
          <b-button size="sm" variant="success" @click="saveEvidence(false)" v-show="isCompleted(currentActivity)">
            Mark as Incomplete
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
    </template>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import {router} from '../routes.js'
import {AuthLevelEnum, ObjectiveStatusEnum, EvidenceTypeEnum} from "./../enums"
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import TutorGroupList from './TutorGroupList.vue';
import Objectives from './Objectives.vue';
//import {VueSelect} from 'vue-select'

export default {
  components: { TutorGroupList, Objectives },
  name: 'Staff',
  props: {
    person: Object
  },
  data() {
      return {
        awardType: 'Tommy',
        fields1: ['studentName', 'award', 'objective', 'evidence', 'actions'],
        fields: ['studentName', 'award', {key: 'categoryName', label: 'Category'}, {key: 'objectiveName', label: 'Objective'}, {key: 'evidence.description', label: 'Evidence'}, {key: 'evidence.studentId', label: 'Actions'}],
        activities: [], //[{"evidence":{"evidenceId":2,"objectiveId":1,"studentId":"20SGRAIHu","description":"I presented a form time presentation on the subject of nerf herding.\r\nA very mis-understood and maligned occupation and should have much\r\nhigher\r\nrecognition.","status":2,"approver":"GSA"},"studentName":"Harry Grant","categoryName":"Leadership","award":"Bronze","categoryId":1,"objectiveId":1,"name":"Lead Form Time Presentation","status":2,"evidenceType":0,"defaultApprover":null, rejectionreason:''}],
        awardFields: ['studentName', 'form', 'awardName'], //, {key: 'studentId', label: 'Actions'}],
        awards: [],
        showReject: false,
        currentActivity: {"evidence":{"evidenceId":2,"objectiveId":1,"studentId":"", description:"Some evidence","status":2,"approver":"GSA"},"studentName":"","categoryName":"","award":"Bronze","categoryId":1,"objectiveId":1,"name":"","status":2,"evidenceType":0,"defaultApprover":null, rejectionreason:''},
        students:[],
        selectedStudent: {awards:[{awardType: 'tommy', awardName: 'Bronze', categories:[]}]},
        evidenceType: EvidenceTypeEnum,
        objectiveStatus: ObjectiveStatusEnum,
        authLevel: AuthLevelEnum,
        tabIndex: 0,
        //currentActivity: {id: 10, description: "Lead form time presentation", status:"complete", evidence: {}},
        cachedActivity: null,
        showActivity: false,
        awardTypes: ['Bronze', 'Silver'],
        selectedAwardType: 'Bronze',
        tutorGroups: ['7S', '7T', '7R', '7B', '7C'],
        selectedTutorGroup: ''
      }
    },
  methods: {
    rejectEvidenceDialog(activity) {
      this.currentActivity = activity;
      this.showReject = true;
    },
    rejectEvidence() {
      //this.rejectToast();
      var that = this;
      axios.put(window.API_URL + 'evidence/' + this.currentActivity.evidence.evidenceId + '/reject', {'reason': this.currentActivity.rejectionReason}).then(function(response) {
        console.log(response);
        that.currentActivity.evidence.status = 1;
        //that.$bvToast.show('reject-toast');
        that.rejectToast();
      });
      
      this.showReject = false;
    },
    undoApproval() {
      var that = this;
      axios.put(window.API_URL + 'evidence/' + this.currentActivity.evidence.evidenceId + '/undo').then(function(response) {
        console.log(response);
        that.currentActivity.evidence.status = 2;
      });
    },
    undoAward(studentId) {
      var that = this;
      axios.put(window.API_URL + 'students/' + studentId + '/undoaward').then(function(response) {
        console.log(response);
        that.undoAwardToast()
      });
    },
    approveEvidence(evidence) {
      this.currentActivity = evidence;
      var that = this;
      axios.put(window.API_URL + 'evidence/' + this.currentActivity.evidence.evidenceId + '/approve').then(function(response) {
        console.log(response);
        that.currentActivity.evidence.status = 3;
        //that.$bvToast.show('approve-toast');
        that.approveToast();
      });
      
    },
    rejectToast() {
        this.$bvToast.toast(`You rejected ${this.currentActivity.studentName}'s evidence for ${this.currentActivity.objectiveName}`, {
          title: 'Evidence Rejected',
          autoHideDelay: 6000,
          variant: 'danger',
          appendToast: false
          
        })
    },
    approveToast() {
        this.$bvToast.toast(`You approved ${this.currentActivity.studentName}'s evidence for ${this.currentActivity.objectiveName}`, {
          title: 'Evidence Approved',
          autoHideDelay: 6000,
          variant: 'success',
          appendToast: false
          
        })
    },
    awardToast() {
        this.$bvToast.toast(`You gave ${this.currentActivity.studentName} a Tommy's Award`, {
          title: 'Award Given',
          autoHideDelay: 6000,
          variant: 'success',
          appendToast: false
        })
    },
    undoAwardToast() {
        this.$bvToast.toast(`You undid a Tommy's Award`, {
          title: 'Award Removed',
          autoHideDelay: 6000,
          variant: 'danger',
          appendToast: false
        })
    },
    getTitle() {
      if ( this.awardType) {
        switch ( this.awardType.toLowerCase()) {
          case 'smart':
            return 'SMART Objectives';
          case 'reading':
            return 'Reading Award';
          default:
          return 'Tommy\'s Award';
        }
      }
      return '';
    },
    getEvidence(description) {
      if (description && Array.from(description)[0] === '[') {
        let evidence = JSON.parse(description);
        let result = evidence.reduce((res, current) => res += current.value + '(' + current.optionName + '), ', '');
        
        return result;
      }
      return description;
    },
    getEvidenceArray(description) {
      if (description && Array.from(description)[0] === '[') {
        let evidence = JSON.parse(description);
        //let result = evidence.reduce((res, current) => res += current.value + '(' + current.optionName + '), ', '');
        
        return evidence;
      }
      return [{value: '', evidence: description, optionName: ''}];
    },
    pending() {
      return this.activities.filter(activity => activity.evidence.status === ObjectiveStatusEnum.Complete);
      //return this.activities;
    },
    approved() {
      return this.activities.filter(activity => activity.evidence.status === ObjectiveStatusEnum.Approved);
    },
    rejected() {
      return this.activities.filter(activity => activity.evidence.status === ObjectiveStatusEnum.Incomplete);
    },
    completed(activities) {
      if (activities) {
        return activities.filter(activity => activity.status >= ObjectiveStatusEnum.Complete)
      }
      return 0;
    },
    incomplete(activities) {
      if (activities) {
        return activities.filter(activity => activity.status < ObjectiveStatusEnum.Complete)
      }
      return 0;
    },
    approvedObjectives(activities) {
      if (activities) {
        return activities.filter(activity => activity.status === ObjectiveStatusEnum.Approved)
      }
      return 0;
      
    },
    notApproved(activities) {
      if (activities) {
        return activities.filter(activity => activity.status === ObjectiveStatusEnum.Complete)
      }
      return 0;
      
    },
    onStudentSelect(value) {
      console.log('Student', value.userName);
      this.showStudent(value.userName);
    },
    onStudentSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.studentSearch(loading, search, this);
      }
    },
    studentSearch: _.debounce((loading, search, vm) => {
      fetch(
        `${window.API_URL}search/students?q=${escape(search)}`
      ).then(res => {
        res.json().then(json => (vm.students = json));
        loading(false);
      });
    }, 750),
    showStudent(studentId){
      console.log(studentId);
      if (this.person.authLevel == AuthLevelEnum.Admin) {
        this.tabIndex = 2;
      } else {
        this.tabIndex = 1;
      }
      let that = this;
      axios.get(window.API_URL + this.awardType +  '/students/' + studentId).then(function(response) {
        console.log(response);
        that.selectedStudent = response.data;
      }).catch(function(err){
        console.log(err);
        that.selectedStudent = {awards:[{awardName: 'None', categories:[]}]};
      });
    },
    awardStudent(studentId){
      console.log(studentId);
      let that = this;
      
      axios.post(window.API_URL + 'awards/' + this.awardType + '/awarded/' + studentId).then(function(response) {
        console.log(response);
        that.selectedStudent = response.data;
        that.awardToast();
      }).catch(function(err){
        console.log(err);
        that.selectedStudent = {awards:[{awardName: 'None', categories:[]}]};
      });
    },
    editActivity(activity){
      var that = this;
      this.validEvidence = null;
      if (!activity.evidence) {
        if(this.isAPI(activity)) {
          activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.person.userName, description: "95%", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover, value: 97};
          if (activity.evidenceType == this.evidenceType.Api_Threshold)  {
            axios.get(window.API_URL + 'attendance?student=' + this.selectedStudent.userName).then(function(response) {
                        activity.evidence.value = response.data;
                        activity.evidence.description = response.data + '%';
                        that.cachedActivity = activity;
                        that.currentActivity = Object.assign({}, activity);
                        that.currentActivity.evidence = Object.assign({}, activity.evidence);
                        that.showActivity = true;
                      });
          }
          else if (activity.evidenceType == this.evidenceType.Api_Count) {
            axios.get(window.API_URL + 'merits?student=' + this.selectedStudent.userName).then(function(response) {
                        activity.evidence.value = response.data;
                        activity.evidence.description = response.data;
                        that.cachedActivity = activity;
                        that.currentActivity = Object.assign({}, activity);
                        that.currentActivity.evidence = Object.assign({}, activity.evidence);
                        that.showActivity = true;
                      });
          }
        }
        else if(activity.status == ObjectiveStatusEnum.NotStarted) {
          activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.selectedStudent.userName, description: "", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover};
          this.cachedActivity = activity;
          this.currentActivity = Object.assign({}, activity);
          this.currentActivity.evidence = Object.assign({}, activity.evidence);
          this.showActivity = true;
        }
        else {
          axios.get(window.API_URL + 'evidence/' + activity.objectiveId + '?student=' + this.selectedStudent.userName).then(function(response) {
            console.log('Evidence', response);
            activity.evidence = response.data;
            that.cachedActivity = activity;
            that.currentActivity = Object.assign({}, activity);
            that.currentActivity.evidence = Object.assign({}, activity.evidence);
            that.showActivity = true;
          });
        }
      }
      else {
        this.cachedActivity = activity;
        this.currentActivity = Object.assign({}, activity);
        this.currentActivity.evidence = Object.assign({}, activity.evidence);
        this.showActivity = true;
      }
      
    },
    isAPI(activity){
      return (activity.evidenceType == this.evidenceType.Api_Threshold || activity.evidenceType == this.evidenceType.Api_Count);
    },
    isCompleted(activity) {
      return activity.status >= this.objectiveStatus.Complete;
    },
    isEditable(activity) {
      return activity.status < this.objectiveStatus.Complete;
    },
    isCompletable(activity) {
      if (activity.status >= this.objectiveStatus.Complete) {
        return false;
      }

      if (activity.evidenceType == this.evidenceType.Api_Threshold) {
        // Need to have finished all other objectives
        return this.person.objectivesOutstanding === 1; // This is that last Objective
      } 
      else if (activity.evidenceType == this.evidenceType.Api_Count) {
        return true;
      }
      return true;
    },
    saveEvidence(completed) {
      // Validation
      if (!this.currentActivity.evidence.description) {
        this.validEvidence = false;
        return;
      }
      this.showActivity = false;
      this.currentActivity.evidence.oldStatus = this.currentActivity.status;
      // As staff completed activities are automatically approved
      var status = completed? ObjectiveStatusEnum.Approved : ObjectiveStatusEnum.Incomplete;
      var that = this;
      this.currentActivity.evidence.status = status;
      this.currentActivity.status = status;
      if (this.currentActivity.evidence.oldStatus == ObjectiveStatusEnum.NotStarted) {  
        axios.post(window.API_URL + 'evidence', this.currentActivity.evidence).then(function(response) {
          console.log(response);
          that.cachedActivity.status = status;
          that.cachedActivity.evidence = response.data;
        });
      }
      else {
        axios.put(window.API_URL + 'evidence', this.currentActivity.evidence).then(function(response) {
          console.log(response);
          that.cachedActivity.status = status;
          that.cachedActivity.evidence = response.data;
        });
      }
    },
    exportAwards() {
      let csv = 'Name,Form,Award\n';
      this.awards.forEach((row) => {
              csv += row.studentName + ',' + row.form + ',' + row.awardName + "\n";
      });
  
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'awards.csv';
      anchor.click();
      let that = this;
      axios.put(window.API_URL + 'awards/' + this.awardType + '/awarded').then(function(response) {
        console.log(response);
        that.awards = [];
      });

    },
    pendingAwards(awards) {
      return awards.filter(award => award.status == 1)
    }
  },
  mounted: function() {
    console.log("Staff Mounted", this.person)
    //axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
    if (this.person.authLevel < AuthLevelEnum.Staff) {
      router.push({name: 'home'})
    }
    else {
      this.awardType = this.$route.params.award;
      if (!this.awardType) {
        this.awardType = 'tommy';
      }
      if ( this.awardType.toLowerCase() == 'tommy') {
        this.awardTypes = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
        this.selectedAwardType = 'Bronze';
      } else if ( this.awardType.toLowerCase() == 'reading') {
        this.awardTypes = ['Year 7', 'Year 8', 'Year 9', 'Year 10', 'Year 11'];
        this.selectedAwardType = 'Year 7';
      }
      let that = this;
      axios.get(window.API_URL + 'awards/' + this.awardType + '/evidence/pending').then(function(response) {
        //console.log(response);
        that.activities = response.data;
      });
      axios.get(window.API_URL + 'awards/' + this.awardType + '/pending').then(function(response) {
        //console.log(response);
        that.awards = response.data;
      });
      if (this.person.authLevel === AuthLevelEnum.Admin || this.person.headOfYear) {
        let url = window.API_URL + 'forms';
        if (this.person.headofYear) {
            url += '?year=' + this.person.headofYear;
        }
        axios.get(url).then(function(response) {
          //console.log(response);
          that.tutorGroups = response.data;
        });
      }
    }
  },
  computed: {
    currentEvidence() {
      if (this.currentActivity && this.currentActivity.evidence) {
        return this.currentActivity.evidence.description;
      }
      return '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.whitespace {
  white-space: pre-line;
  max-width: 150px;
}
.done {
  background-color: #45ce3960;
}
.todo {
  background-color: #ce9a3960;
}
.title{
  max-height: 5em;
  background-color: rgba(3,26,70,.25);
  padding-top: .2rem;
}

h4.card-title {
  padding-top: .2rem;
  font-size: 1.2rem;
}

.card-text {
  margin-bottom: .5rem;
}

.student-header > .card-body {
  padding-top: .8rem;
  padding-bottom: .8rem;
}
</style>
