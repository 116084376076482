<style>
  .bg-complete, .bg-status2, .bg-status3 {
    background-color: #45ce3960;
  }
</style>

<template>
  <b-container>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/student">Student Awards Portal</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-navbar-nav>
            <b-nav-item to="/student">Tommy's Award</b-nav-item>
            <b-nav-item to="/smartobjectives">SMART Objectives</b-nav-item>
            <b-nav-item to="/readingaward">Reading Award</b-nav-item>
          </b-navbar-nav>
      </b-navbar>
    </div>
    <!-- Header -->
    <!-- ##################################################################### -->
    <b-card class="text-center student-header" body-bg-variant="info" body-text-variant="white">
      <b-img left src="images\strslogo-sm.png" alt="Right image"></b-img><b-img right src="images\strslogo-sm.png" alt="Right image"></b-img>
      <h6>Hello, <strong>{{person.name}}</strong></h6>
      <div v-if="awarded" class="awarded-banner">
        <h1>Congratulations!</h1>
        <h2>You have completed your Tommy's Reading Award!</h2>
      </div>
      <div v-else>
        <h2>Your Reading Award Progress</h2>
        <h6>You have completed {{getAward('reading').objectivesCompleted + getAward('reading').objectivesApproved}} out of 
          {{getAward('reading').objectivesCompleted + getAward('reading').objectivesApproved + getAward('reading').objectivesOutstanding}} objectives for your <strong>{{getAward('reading').awardName}}</strong> award</h6>
      </div>
    </b-card>
    <b-alert v-model="showRejectedMessages" variant="danger" >
      <p v-for="message in rejectedMessages()" :key="message.messageId">
        Completion of objective <strong>{{message.objective}}</strong> was not approved because: <strong>{{message.messageText}}</strong>
      </p>
      <p><a href="#" @click="markMessagesRead()" variant="primary">Mark messages as read</a></p>
    </b-alert>
    <b-alert v-model="showApprovedMessages" variant="success" >
      <p v-for="message in approvedMessages()" :key="message.messageId">
        Completion of objective <strong>{{message.objective}}</strong> was <strong>approved</strong>
      </p>
      <p><a href="#" @click="markMessagesRead()" variant="primary">Mark messages as read</a></p>
    </b-alert>
    <!-- ##################################################################### -->    
    <div>
    

    <b-card v-for="activity in activities" :key="activity.objectiveId" header-tag="header" no-body :bg-variant="'status' + activity.status">
      <template #header>
      <div style="display: flex; justify-content:left;">
        <h5 class="mb-0"><a @click="editActivity(activity)" >{{ activity.name }} <span v-if="activity.status==objectiveStatus.Complete">(Pending)</span></a> </h5><span style="display:flex; flex-grow: 1; justify-content: flex-end;"><b-button size="sm" variant="outline-primary" @click="editActivity(activity)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button></span>
      </div>
      </template>
    </b-card>
    
  </div>


    

    <!-- Modals -->
    <!-- ##################################################################### -->
    <!-- Objective/Activity -->
    <!-- ##################################################################### -->
    <b-modal v-model="showActivity" :title="currentActivity.name">

        <b-form-group v-if="currentActivity.evidenceType == this.evidenceType.Text" description="Add Evidence for completing this activity" label="Evidence" label-for="evidence">
          <b-form-textarea id="evidence"  v-model="currentActivity.evidence.description" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
        </b-form-group>
        <!-- If list -->
        <!-- ##################################################################### -->
        <b-form-group v-if="currentActivity.evidenceType == this.evidenceType.List" description="Add Evidence for completing this activity" label="Evidence" label-for="evidence">
          <b-card v-for="section in currentActivity.evidence.sections" :key="section.sectionId" no-body>
            <p>{{section.optionName}} <b-button size="sm" @click="editSection(section)" variant="outline-primary"><b-icon-pencil-fill></b-icon-pencil-fill></b-button></p>
            <!--<b-form-group :label="section.optionName">
              <b-form-textarea id="evidence"  v-model="section.evidence"></b-form-textarea>
            </b-form-group>-->
          </b-card>
          <b-button size="sm" @click="addSection()">Add</b-button>
        </b-form-group>
        <!-- ##################################################################### -->
        <!-- If open list -->
        <!-- ##################################################################### -->
        <b-form-group v-if="currentActivity.evidenceType == this.evidenceType.OpenList" description="Add Evidence for completing this activity" label="Evidence" label-for="evidence">
          <b-card v-for="section in currentActivity.evidence.sections" :key="section.sectionId" no-body>
            
            <div style="display: flex; justify-content: center; padding: 4px;"><div>{{ getOpenListSectionName(section) }}</div>
            <span style="display:flex; flex-grow: 1; justify-content: flex-end;"><b-button size="sm" 
              @click="editOpenSection(section)" variant="outline-primary" ><b-icon-pencil-fill></b-icon-pencil-fill>
            </b-button></span></div>
            <!--<b-form-group :label="section.optionName">
              <b-form-textarea id="evidence"  v-model="section.evidence"></b-form-textarea>
            </b-form-group>-->
          </b-card>
          <b-button size="sm" @click="addOpenSection()">Add</b-button>
        </b-form-group>
        <!-- ##################################################################### -->
        
        <b-form-text :text-variant="criteriaMet(currentActivity)? 'success': 'info'" v-if="isAPI(currentActivity)">
          <strong>Currently at {{currentActivity.evidence.description}}</strong><br>
          <span v-if="!isCompletable(currentActivity) && currentActivity.evidenceType === evidenceType.Api_Threshold">This objective can only be completed once all other objectives are complete and the threshold has been met.</span>
          <span v-if="!isCompletable(currentActivity) && currentActivity.evidenceType === evidenceType.Api_Count">This objective can only be completed once the threshold has been met.</span>
        </b-form-text>
        <b-form-group v-if="!isAPI(currentActivity)" description="Enter the member of staff to approve the evidence" label="Approval" label-for="approver">
          <b-form-select id="approver" v-model="currentActivity.evidence.approver" :disabled="currentActivity.status >= objectiveStatus.Complete">
            <b-form-select-option  v-for="staffMember in staff" :key="staffMember.userName" :value="staffMember.userName">{{staffMember.name}}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-text text-variant="success" v-if="currentActivity.status==objectiveStatus.Approved"><strong>Completed and approved</strong></b-form-text>
        <b-form-text text-variant="info" v-else-if="currentActivity.status==objectiveStatus.Complete"><strong>Completed, pending approval</strong></b-form-text>
        <b-form-text text-variant="danger" v-else><strong>Incomplete</strong></b-form-text>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="outline-secondary" @click="saveEvidence(false)" v-show="isEditable(currentActivity) && !isAPI(currentActivity)"> 
            Save
          </b-button>
          <!-- Button with custom close trigger value -->
          <b-button size="sm" variant="success" @click="saveEvidence(true)" v-show="isCompletable(currentActivity)">
            Submit
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
        </template>
    </b-modal>
    
    <!-- ##################################################################### -->
    <!-- List item  -->
    <!-- ##################################################################### -->
    
    <b-modal title="Add" @ok="saveSection" v-model="showOption">
      <b-form-group label="Review">
        <b-form-textarea id="sectionEvidence"  v-model="currentSection.optionName" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
      </b-form-group>
      <b-form-group label="Evidence" label-for="book-review">
          <b-form-textarea id="sectionEvidenceDetail"  v-model="currentSection.evidence" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
        </b-form-group>
    </b-modal>

    <!-- ##################################################################### -->
    <!-- Open List item  -->
    <!-- ##################################################################### -->
    <b-modal title="Add" @ok="saveSection" v-model="showOpenOption">
      <b-form-group :label="currentActivity.settings.textLabel">
        <b-form-textarea id="sectionEvidence"  v-model="currentSection.value" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
      </b-form-group>
      <b-form-radio-group label="Section Type" label-for="section-type" v-model="currentSection.optionId" :disabled="currentActivity.status >= objectiveStatus.Complete">
        <b-form-radio  v-for="option in currentOptions" :key="option.optionId" :value="option.optionId" >{{ option.optionName }}</b-form-radio> 
      </b-form-radio-group>
      <b-form-group label="Evidence" label-for="book-review">
          <b-form-textarea id="sectionEvidenceDetail"  v-model="currentSection.evidence" :readonly="currentActivity.status >= objectiveStatus.Complete"
            :state="validEvidence"></b-form-textarea>
        </b-form-group>
    </b-modal>
    <!-- ##################################################################### -->
  </b-container>
</template>

<script>

import axios from 'axios';
import {AuthLevelEnum, ObjectiveStatusEnum, EvidenceTypeEnum} from "../enums"
import {router} from '../routes.js'
import confetti from 'canvas-confetti'

export default {
  name: 'ReadingAward',
  components: {  },
  props: {
    person: Object
  },
  data() {
      return {
        showActivity: false,
        showReadingActivity: false,
        showOpenOption: false,
        activities: [],
        activities1: [{categoryId:1, objectiveId:1, name:"Read 10 books. Including 2 eBooks or Audiobooks from Wheeler's ePlatform", "status": 0,
                "evidenceType": 1,
                "defaultApprover": "CDC",
                "awardId": null,
                "successCriteria": null,
                "options": [],
                "sections":[],
                "active": false},
                {categoryId:1, objectiveId:2, name:"Attend Book club", "status": 0,
                "evidenceType": 1,
                "defaultApprover": "CDC",
                "awardId": null,
                "successCriteria": null,
                "options": [],
                "sections":[],
                "active": false},
                {categoryId:1, objectiveId:3, name:"Write 2 book reviews or film a short review for Book of the Month or give a presentation on one of your Tutor Time Reading books", "status": 0,
                "evidenceType": EvidenceTypeEnum.Text,
                "defaultApprover": "CDC",
                "awardId": null,
                "successCriteria": null,
                "options": [],
                "sections":[],
                "active": false},
                {categoryId:1, objectiveId:3, name:"Dress up as a book character for World Book Day", "status": 0,
                "evidenceType": EvidenceTypeEnum.Text,
                "defaultApprover": "CDC",
                "awardId": null,
                "successCriteria": null,
                "options": [],
                "sections":[],
                "active": false},
                {categoryId:1, objectiveId:3, name:"Participate in 2 World Book Day Activities", "status": 0,
                "evidenceType": EvidenceTypeEnum.Text,
                "defaultApprover": "CDC",
                "awardId": null,
                "successCriteria": null,
                "options": [],
                "sections":[],
                "active": false}],
        currentActivity: {id: 10, description: "Lead form time presentation", status:"complete", evidence: {}, settings: {textLabel:'Add'}},
        cachedActivity: null,
        currentEvidence: {evidenceId: 0, objectiveId:0, StudentId: "", description: "", status: ObjectiveStatusEnum.NotStarted, approver: ""},
        staff: ['Him', 'AKM', 'RTY'],
        evidenceType: EvidenceTypeEnum,
        objectiveStatus: ObjectiveStatusEnum,
        validEvidence: true,
        messages: [],
        showRejectedMessages: false,
        showApprovedMessages: false,
        awarded: false,
        currentOptions: [], //{optionId: 1, description: "Hitchhikers Guide to the Galaxy"}, {optionId: 2, description: "Narnia"}, {optionId: 3, description: "The Great Gatsby"}],
        currentSection: {sectionId: null, optionId: null, optionName: null, evidence: '', bookType: '0'},
        showOption: false,
        newSection: true,
        bookType: 0
      }
    },
  methods: {
    completed(activities) {
      return activities.filter(activity => activity.status >= ObjectiveStatusEnum.Complete)
    },
    incomplete(activities) {
      return activities.filter(activity => activity.status < ObjectiveStatusEnum.Complete)
    },
    approved(activities) {
      return activities.filter(activity => activity.status === ObjectiveStatusEnum.Approved)
    },
    notApproved(activities) {
      return activities.filter(activity => activity.status === ObjectiveStatusEnum.Complete)
    },
    isAPI(activity){
      return (activity.evidenceType == this.evidenceType.Api_Threshold || activity.evidenceType == this.evidenceType.Api_Count);
    },
    criteriaMet(activity){
      if (this.isAPI(activity) && activity.evidence.value >= parseInt(activity.successCriteria, 10)) {
        return true;
      }
      return false;
    },
    isEditable(activity) {
      return activity.status < this.objectiveStatus.Complete;
    },
    isCompletable(activity) {
      if (activity.status >= this.objectiveStatus.Complete) {
        return false;
      }

      if (activity.evidenceType == this.evidenceType.Api_Threshold) {
        // Need to have finished all other objectives
        return this.person.objectivesOutstanding === 1 && this.criteriaMet(activity); // This is the last Objective
      } 
      else if (activity.evidenceType == this.evidenceType.Api_Count) {
        return this.criteriaMet(activity);
      }
      return true;
    },
    getOpenListSectionName(section) {
      // {{section.value}} ({{ section.optionName }})
      if (section.optionName) {
        return section.value + ' (' + section.optionName + ')';
      } else {
        return section.value;
      }
    },
    editActivity(activity){
      var that = this;
      this.validEvidence = null;
      if (!activity.evidence) {
        if(this.isAPI(activity)) {
          
          activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.person.userName, description: "95%", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover, value: 97};
          if (activity.evidenceType == this.evidenceType.Api_Threshold)  {
            axios.get(window.API_URL + 'attendance').then(function(response) {
                        activity.evidence.value = response.data;
                        activity.evidence.description = response.data + '%';
                        that.cachedActivity = activity;
                        that.currentActivity = Object.assign({}, activity);
                        that.currentActivity.evidence = Object.assign({}, activity.evidence);
                        that.showActivity = true;
                      });
          }
          else if (activity.evidenceType == this.evidenceType.Api_Count) {
            axios.get(window.API_URL + 'merits').then(function(response) {
                        activity.evidence.value = response.data;
                        activity.evidence.description = response.data;
                        that.cachedActivity = activity;
                        that.currentActivity = Object.assign({}, activity);
                        that.currentActivity.evidence = Object.assign({}, activity.evidence);
                        that.showActivity = true;
                      });
          }
          
          
        }
        else if(activity.status == ObjectiveStatusEnum.NotStarted) {
          activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.person.userName, description: "", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover};
          this.cachedActivity = activity;
          this.currentActivity = Object.assign({}, activity);
          this.currentActivity.evidence = Object.assign({}, activity.evidence);
        
          this.currentOptions = activity.options;
                  
          this.showActivity = true;
        }
        else {
          axios.get(window.API_URL + 'evidence/' + activity.objectiveId).then(function(response) {
            console.log('Evidence', response);
            activity.evidence = response.data;
            if (activity.evidenceType === EvidenceTypeEnum.List || activity.evidenceType === EvidenceTypeEnum.OpenList) {
              activity.evidence.sections = JSON.parse(activity.evidence.description);
              that.currentOptions = activity.options;
            }
            that.cachedActivity = activity;
            that.currentActivity = Object.assign({}, activity);
            that.currentActivity.evidence = Object.assign({}, activity.evidence);
            that.showActivity = true;
          });
        }
      }
      else {
        this.cachedActivity = activity;
        this.currentActivity = Object.assign({}, activity);
        this.currentActivity.evidence = Object.assign({}, activity.evidence);
        this.showActivity = true;
      }
      
    },
    editReadingActivity(activity){
      var that = this;
      this.validEvidence = null;
      if (!activity.evidence) {          
          // activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.person.userName, description: "95%", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover, value: 97};
          
        
        if(activity.status == ObjectiveStatusEnum.NotStarted) {
          activity.evidence = {evidenceId: 0, objectiveId: activity.objectiveId, studentId: this.person.userName, description: "", status: ObjectiveStatusEnum.NotStarted, approver: activity.defaultApprover};
          this.cachedActivity = activity;
          this.currentActivity = Object.assign({}, activity);
          this.currentActivity.evidence = Object.assign({}, activity.evidence);
          this.currentOptions = activity.options;
          
          this.showReadingActivity = true;
        }
        else {
          axios.get(window.API_URL + 'evidence/' + activity.objectiveId).then(function(response) {
            console.log('Evidence', response);
            activity.evidence = response.data;
            if (activity.evidenceType === EvidenceTypeEnum.List || activity.evidenceType === EvidenceTypeEnum.OpenList) {
              activity.evidence.sections = JSON.parse(activity.evidence.description);
              that.currentOptions = activity.options;
            }
            that.cachedActivity = activity;
            that.currentActivity = Object.assign({}, activity);
            that.currentActivity.evidence = Object.assign({}, activity.evidence);
            that.showReadingActivity = true;
          });
        }
      }
      else {
        this.cachedActivity = activity;
        this.currentActivity = Object.assign({}, activity);
        this.currentActivity.evidence = Object.assign({}, activity.evidence);
        this.showReadingActivity = true;
      }
      
    },
    addSection() {
      this.currentSection = {optionId: null, optionName: null, evidence: ''};
      this.newSection = true;
      this.showOption = true;
    },
    addOpenSection() {
      
      this.currentSection = {optionId: null, optionName: null, evidence: ''};
      if (this.currentOptions && this.currentOptions.length) {
        this.currentSection.optionName = this.currentOptions[0].optionName;
        this.currentSection.optionId = this.currentOptions[0].optionId;
      }
      this.newSection = true;
      this.showOpenOption = true;
    },
    editSection(section) {
      this.currentSection = section;
      // this.currentOptionId = section.optionId;
      // this.newSectionEvidence = section.evidence;
      this.newSection = false;
      if (this.currentActivity.evidenceType === EvidenceTypeEnum.List) {
        this.showOption = true;
      } else {
        this.showOpenOption = true;  
      }
    },
    editOpenSection(section) {
      this.currentSection = section;
      // this.currentOptionId = section.optionId;
      // this.newSectionEvidence = section.evidence;
      this.newSection = false;
      this.showOpenOption = true;
    },
    saveSection() {
      // const newItem = {optionId: this.currentOptionId, optionName: this.currentOptions.filter(f => f.optionId === this.currentOptionId)[0].optionName, evidence: this.newSectionEvidence};
      //console.log(this.currentOptionId)
      //console.log(newItem);
      if (this.currentOptions && this.currentOptions.length) {
        this.currentSection.optionName = this.currentOptions.filter(f => f.optionId === this.currentSection.optionId)[0].optionName;
      }
      
      if (this.newSection) {
        this.currentActivity.evidence.sections = this.currentActivity.evidence.sections || [];
        // this.currentSection.sectionId = this.currentActivity.evidence.sections.length;
        this.currentActivity.evidence.sections.push( Object.assign({}, this.currentSection));
      }
      this.showOption = false;
    },
    getApprover(activity) {
      return activity.approver!=null ? activity.approver : activity.defaultApprover;
    },
    getBookType(id){
      switch(id) {
        case '1':
          return '(e-book)';
        case '2':
          return '(Audiobook)';
        default:
          return '';
      }
    },
    saveEvidence(submitForApproval) {
            
      this.showActivity = false;
      var oldStatus = this.currentActivity.status;
      var status = submitForApproval? ObjectiveStatusEnum.Complete : ObjectiveStatusEnum.Incomplete;
      if (this.currentActivity.evidenceType === EvidenceTypeEnum.Api_Threshold || this.currentActivity.evidenceType === EvidenceTypeEnum.Api_Count) {
        // Don't need approval
        status = ObjectiveStatusEnum.Approved;
        this.currentActivity.evidence.description = 'Met requirements';
      }
      else if (this.currentActivity.evidenceType === EvidenceTypeEnum.List || this.currentActivity.evidenceType === EvidenceTypeEnum.OpenList) {
        console.log(JSON.stringify(this.currentActivity.evidence.sections))
        this.currentActivity.evidence.description = JSON.stringify(this.currentActivity.evidence.sections);
      }
      else if (!this.currentActivity.evidence.description) { // Validation
        this.validEvidence = false;
        return;
      }
      var that = this;
      this.currentActivity.evidence.status = status;
      this.currentActivity.status = status;
      
      if (oldStatus == ObjectiveStatusEnum.NotStarted) {  
        axios.post(window.API_URL + 'evidence', this.currentActivity.evidence).then(function(response) {
          console.log(response);
          that.cachedActivity.status = status;
          that.cachedActivity.evidence = response.data;
          if (that.cachedActivity.evidenceType == that.evidenceType.Api_Threshold) {
            // This is be the last completed activity
            
          }

        });
      }
      else {
        axios.put(window.API_URL + 'evidence', this.currentActivity.evidence).then(function(response) {
          console.log(response);
          that.cachedActivity.status = status;
          that.cachedActivity.evidence = response.data;
        });
      }
    },
    approvedMessages(){
      if (this.messages) {
        return this.messages.filter(message => message.messageType == 4);
      }
      return [];
    },
    rejectedMessages(){
      if (this.messages) {
        return this.messages.filter(message => message.messageType == 2);
      }
      return [];
    },
    markMessagesRead() {
      axios.post(window.API_URL + 'objectives/reading/messagesread').then(function(response) {
          console.log(response);
        });
        this.messages = [];
        this.showRejectedMessages = false;
        this.showApprovedMessages = false;
    },
    getAward(awardType) {
      console.log(this.person)
      if (this.person && this.person.awards) {
        console.log(this.person.awards.filter(a=>a.awardType.toLowerCase() === awardType.toLowerCase()))
        return this.person.awards.filter(a=>a.awardType.toLowerCase() === awardType.toLowerCase())[0];
      }
      return {objectivesCompleted: 0, objectivesPending: 0};
    },
    fireConfetti() {

      var count = 200;
      var defaults = {
        origin: { y: 0.7 }
      };

      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }
  },
  created: function() {
    var that = this;

    console.log("Student Mounted")
    //axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
    if (!this.person || this.person.authLevel !== AuthLevelEnum.Student) {
      this.person.award = {awardName: 'None'};
      router.replace({name: 'home'})  
    }
    else {
      axios.get(window.API_URL + 'awards/reading/objectives').then(function(response) {
        //axios.get(window.API_URL + 'readingobjectives').then(function(response) {
        console.log(response);
        that.activities = response.data[0].objectives;
        that.activities.forEach(activity => {
          if(activity.settings) {
            activity.settings = JSON.parse(activity.settings);
          } 
        });
      });

      axios.get(window.API_URL + 'staff').then(function(response) {
        //console.log(response);
        that.staff = response.data;
      });

      if (this.getAward('reading').status === 2) {
        this.awarded = true;
      }
      else {
        axios.get(window.API_URL + 'objectives/reading/messages').then(function(response) {
          //console.log(response);
          that.messages = response.data;
          that.showRejectedMessages = that.rejectedMessages().length > 0;
          if (that.approvedMessages().length > 0) {
            that.showApprovedMessages = true;  
            //setTimeout(that.fireConfetti, 2500);
            //setTimeout(that.fireConfetti, 4000);
          }      
        });
      }
    }  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.done {
  background-color: #45ce3960;
}
.done-text {
  color: #45ce3960;
  font-weight: bold;
}
.todo {
  background-color: #ce9a3960;
}
.strsblue {
  background-color: rgba(3,26,70,.75);
}
.title{
  max-height: 5em;
}

.title > h4.card-title {
  background-color: rgba(3,26,70,.5);
  padding: .7rem;
  
}

.card-text {
  margin-bottom: .5rem;
}

.student-header > .card-body {
  padding-top: .8rem;
  padding-bottom: .8rem;
}
.awarded-banner {
  height: 200px;
  padding-top: 80px;
  color:lemonchiffon;
  /*align-items: center;
  justify-content:center;
  display: flex;*/
}

.bouncing-text {
  position: relative;
  display: flex;
  font-size: 36px;
  font-family: arial black;
}

@keyframes bounce {
        0%   { transform: scale(1,1) translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1)   translateY(-55px);}
        50%  { transform: scale(1.05,.95) translateY(0); }
        58%  { transform: scale(1,1) translateY(-7px); }
        65%  { transform: scale(1,1) translateY(0);}
        100% { transform: scale(1,1) translateY(0);}
    }

.b {
  animation: bounce 1s ease 0s 3;
}

.o {
  animation: bounce 1s ease .1s 3;
}

.u {
  animation: bounce 1s ease .2s 3;
}

.n {
  animation: bounce 1s ease .3s 3;
}

.c {
  animation: bounce 1s ease .4s 3;
}

.e {
  animation: bounce 1s ease .5s 3;
}
.f {
  animation: bounce 1s ease .6s 3;
}

</style>

