import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueRouter from 'vue-router'
import './plugins/bootstrap-vue'
import App from './App.vue'
import {router}  from './routes.js'
import vSelect from 'vue-select'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.component('v-select', vSelect)
new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
