<template>
    <b-container>
        <b-card-text class="col-6 offset-md-3">
        <h4>Tommy's Award Categories</h4>
            <b-form-group label="Select Award">
            <b-select @change="selectYear($event)" v-model="awardYear">
                    <b-select-option value="7">Year 7</b-select-option>
                    <b-select-option value="8">Year 8</b-select-option>
                    <b-select-option value="9">Year 9</b-select-option>
                    <b-select-option value="10">Year 10/11</b-select-option>
                    <b-select-option value="12">Year 12/13</b-select-option>
            </b-select>
            </b-form-group>
            <b-form-group v-if="awardId">
              
              <b-input-group  prepend="Award Name">
                <b-form-input v-model="awardName" :disabled="!editingName"></b-form-input>
                <b-input-group-append>
                  <b-button variant="info" @click="editingName = true" v-if="!editingName">Edit</b-button>
                  <b-button variant="info" @click="updateName(awardId)" v-if="editingName">Update</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group v-if="awardId">

              <b-input-group  prepend="Approver">
                <b-form-input v-model="awardApprover" :disabled="!editingApprover"></b-form-input>
                
                <b-input-group-append>
                  <b-button variant="info" @click="editingApprover = true" v-if="!editingApprover">Edit</b-button>
                  <b-button variant="info" @click="updateApprover(awardId)" v-if="editingApprover">Update</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="accordion" role="tablist">
              <b-card  v-for="category in categories" :key="category.categoryId" no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="'accordion-' + category.categoryId" variant="info">{{category.categoryName}}</b-button>
              </b-card-header>
              <b-collapse :id="'accordion-' + category.categoryId" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  
                  <hr>
                  <b-list-group v-for="objective in category.objectives" :key="objective.objectiveId">
                    <b-list-group-item><span v-if="objective.active">{{objective.name}}</span><span v-if="!objective.active" style="text-decoration: line-through;">{{objective.name}}</span>
                      <b-button size="sm" variant="outline-primary" class="float-right" @click="editObjective(objective)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button>
                    </b-list-group-item>
  
                  </b-list-group>
                  <b-button class="float-right" size="sm" variant="primary" @click="addObjective(category)">
                    Add
                  </b-button>
                </b-card-body>
              </b-collapse>
            </b-card>
            </div>
            
          </b-card-text>  
          <b-modal v-model="showEditObjective" title="Edit Objective">
    
      <b-form-group label="Name" label-for="name">
        <b-input v-model="currentObjective.name"></b-input>
      </b-form-group>
      <b-form-group label="Evidence Type" label-for="type">
        <b-select v-model="currentObjective.evidenceType">
          <b-select-option value="1">Text</b-select-option>
          <b-select-option value="2">API Count</b-select-option>
          <b-select-option value="3">API Threshold</b-select-option>
          <b-select-option value="4">List</b-select-option>
          <b-select-option value="5">Open List</b-select-option>
          <b-select-option value="6">Linked Objective</b-select-option>
          <b-select-option value="7">SMART Objective</b-select-option>
          <b-select-option value="8">Linked Award</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group description="Enter the default member of staff to approve the evidence" label="Default Approver" label-for="defaultApprover">
        <b-form-select id="approver" v-model="currentObjective.defaultApprover">
          <b-form-select-option  v-for="staffMember in staff" :key="staffMember.userName" :value="staffMember.userName">{{staffMember.name}}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group v-if="currentObjective.evidenceType == 6" label="Linked Objective" label-for="linkedObjective">
        <b-form-select id="linkedObjective" v-model="currentObjective.linkedObjectiveId">
          <b-form-select-option-group v-for="category in linkedObjectives" :key="category.categoryId" :label="category.categoryName">
            <b-form-select-option  v-for="link in category.objectives" :key="link.objectiveId" :value="link.objectiveId">{{link.name}}</b-form-select-option>
          </b-form-select-option-group>
          
        </b-form-select>
      </b-form-group>
      <b-form-group label="Success Criteria" label-for="successCriteria">
        <b-input v-model="currentObjective.successCriteria"></b-input>
      </b-form-group>
      <b-form-group v-if="currentObjective.evidenceType == 4 || currentObjective.evidenceType == 5" label="Options" label-for="options" description="Add list options. Press enter in last option to add new. Delete text to remove">
        <b-card v-for="option in currentObjective.options" :key="option.optionId" no-body>
          <b-input v-model="option.optionName"></b-input>
        </b-card>
        <b-card no-body>
          <b-input v-model="newOption" v-on:keyup.13="newOptionEnter()"></b-input>
        </b-card>
      </b-form-group>
      <b-form-group v-if="currentObjective.evidenceType == 4 || currentObjective.evidenceType == 5" label="Option Label" label-for="optionLabel">
        <b-input v-model="currentObjective.settingsObject.textLabel"></b-input>
      </b-form-group>

      <b-form-group label="Active" label-for="active">
        <b-checkbox v-model="currentObjective.active"></b-checkbox>
      </b-form-group>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="primary" @click="saveObjective()" > 
            OK
          </b-button>
          <b-button size="sm" variant="danger" @click="deleteObjective()" > 
            Delete
          </b-button>
          <b-button size="sm" variant="secondary" @click="cancel()">
            Close
          </b-button>
        </template>
  </b-modal>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

  import axios from 'axios';
  import {router} from '../routes.js'
  import {AuthLevelEnum} from "./../enums"

  export default ({
    name: 'Objectives',
    props: {
      showModal: Boolean,
      person: Object,
      awardType: String
    },
    data() {
      return {
        fields: ['categoryName'],
        categories:[], //{categoryName: 'Leadership', categoryId: 1}, {categoryName: 'Service and Commitment', categoryId: 2}],
        objectives: [{objectiveName: "Take part in Founder's week", objectiveId: 1},{objectiveName: "Attendance 98%", objectiveId:2},{objectiveName: "Give a tour to a school visitor", objectiveId:3}],
        currentObjective: {objectiveName: "Take part in Founder's week", objectiveId: 1},
        showEditObjective: false,
        staff: ['Him', 'AKM', 'RTY'],
        currentCategory: null,
        awardId: null,
        awardYear: null,
        awardName: null,
        awardApprover: '',
        editingApprover: false,
        editingName: false,
        newOption: '',
        linkedObjectives: []
      }
    },
    /* watch: {
      // whenever question changes, this function will run
      newOption(newValue, oldValue) {
        if (newQuestion.indexOf('?') > -1) {
          this.getAnswer()
        }
      }
    },*/
    methods: {
      editObjective(objective) {
        this.currentObjective = objective;
        if (objective.settings) {
          this.currentObjective.settingsObject = JSON.parse(objective.settings);
        }
        else {
          this.currentObjective.settingsObject = {textLabel: ''};
        }
        this.showEditObjective = true;
      },
      addObjective(category) {
        this.currentCategory = category;
        this.currentObjective = {objectiveName: "", evidenceType: 1, categoryId: category.categoryId, awardId: this.awardId, settingsObject: {textLabel: ''}, active: true};
        this.showEditObjective = true;
      },
      deleteObjective() {
        let that = this;
        if (confirm('Are you sure you wish to delete this objective?')) {
          this.currentObjective.active = false;
          axios.put(window.API_URL + 'objectives/' + this.currentObjective.objectiveId, this.currentObjective).then(function(response) {
              console.log(response);
              that.currentObjective = response.data;
          });
          this.showEditObjective = false;
        } 
      },
      saveObjective() {
        let that = this;
        if (this.currentObjective.settingsObject) {
          this.currentObjective.settings = JSON.stringify(this.currentObjective.settingsObject);
        } else {
          this.currentObjective.settings = null;
        }
        if (this.currentObjective.objectiveId) {
          axios.put(window.API_URL + 'objectives/' + this.currentObjective.objectiveId, this.currentObjective).then(function(response) {
            console.log(response);
            that.currentObjective = response.data;
          });
        }
        else {
          axios.post(window.API_URL + 'objectives', this.currentObjective).then(function(response) {
            console.log(response);
            that.currentObjective = response.data;
            that.currentCategory.objectives.push(that.currentObjective);
          });
        }
        this.showEditObjective = false;
      },
      selectYear(event) {
        console.log('event', event)
        // retieve objectives
        let that = this;
        if (event == 'Reading') {
          axios.get(window.API_URL + 'awards/'+ this.awardType + '/objectives?year=0').then(function(response) {
            //console.log(response);
            that.awardId = response.data.awardId;
            that.awardName = response.data.awardName;
            that.categories = response.data.categories;
            that.awardApprover = response.data.approver;
          });
        } else {
          this.awardYear = event;
          axios.get(window.API_URL + 'awards/'+ this.awardType + '/objectives?year=' + event).then(function(response) {
            //console.log(response);
            that.awardId = response.data.awardId;
            that.awardName = response.data.awardName;
            that.categories = response.data.categories;
            that.awardApprover = response.data.approver;
          });
          axios.get(window.API_URL + 'awards/'+ this.awardType + '/linkedobjectives?year=' + event).then(function(response) {
            console.log('linked', response);
            that.linkedObjectives = response.data;
            
          });
      }
      },
      updateApprover() {
        //let that = this;
        if (this.awardApprover.length > 0) {
          axios.put(`${window.API_URL}awards/${this.awardId}/approver`, 'approver='+this.awardApprover).then(function(response) {
            console.log(response);
          });
        }
        
        this.editingApprover = false;
      },
      updateName() {
        //let that = this;
        if (this.awardName.length > 0) {
          axios.put(`${window.API_URL}awards/${this.awardId}/name`, 'name='+this.awardName).then(function(response) {
            console.log(response);
          });
        }
        
        this.editingName = false;
      },
      newOptionEnter() {
        let newOption = {objectiveId: this.currentObjective.objectiveId, optionId: null, optionName: this.newOption};
        this.currentObjective.options.push(newOption);
        this.newOption = '';
      }
    },
    mounted: function() {
      console.log("Admin Mounted", this.person);
      axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
      if (this.person.authLevel === AuthLevelEnum.Student) {
        router.push({name: 'home'})
      }
      else {
        let that = this;
        //axios.get(window.API_URL + 'categories').then(function(response) {
        //console.log(response);
        //that.categories = response.data;
        //});

        axios.get(window.API_URL + 'staff').then(function(response) {
          //console.log(response);
          that.staff = response.data;
        });

      }
  },
})
</script>